import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import actions from "../../redux/actions";
import { useDispatch } from "react-redux";

const Message = () => {
  const message = useSelector((state) => state.message);
  const dispatch = useDispatch();

  if (!message) return null;

  return (
    <Box my={2} align="start" style={{ width: "100%" }}>
      <Alert
        onClose={() => dispatch(actions.message.removeMessage())}
        severity={message.type}
      >
        <AlertTitle variant="h6">{message.title}</AlertTitle>
        <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
          {message.description}
          <a className="underline text-blue-500" href={message.link}>
            {message.linkText}
          </a>
        </Typography>
      </Alert>
    </Box>
  );
};

export default Message;
